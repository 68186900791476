<template>
  <div class="escolha-pg">
    <Voltar />
    <h1 class="titulo">ASSINATURA</h1>
    <p>
      Pronto! Agora precisamos que informe a forma de pagamento para concluir sua
      assinatura e concorrer a prêmios.
    </p>
    <div class="form__select" v-if="!state.mp">
    <label for="semanas">Semanas Disponíveis</label>
    <select name="semanas" v-model="state.semanas" required>
        <option value="9999" v-if="state.promocao.semanas && state.promocao.semanas.length > 1">Toda Semana - R$ {{ state.promocao.semanas[0].valor }} por Semana *</option>
        <option v-for="semana in state.promocao.semanas" :key="semana.id" :value="semana.qtd">
            {{ semana.qtd }} semana(s) - R$ {{ semana.valor }}
        </option>
    </select>
    <p v-if="state.semanas == 9999">
        * Será cobrado do seu cartão de crédito o valor de R$ 5,00 a cada 7 dias!
    </p>
    </div>
    <div class="bt bt--claro" v-if="!state.mp && state.semanas != ''">
      <a href="javascript:void(0);" :disabled="state.isLoading"
        @click="setParcelas"
      >
        Assinar
      </a>
    </div>
    <hr style="margin: 30px 0 30px 0;" />
    <!--
    <div class="bt bt--claro btnMpPix">
      <a href="javascript:void(0);" :disabled="state.isLoading"
        @click="setPix"
      >
        Pagar com PIX
      </a>
    </div>
    -->
    <div class="bt bt--claro">
      <!--
          <img src="../../assets/img/pix.png" alt="" />
      -->
      <a @click="() => router.push({ name: 'Ticket' })">Ticket Impresso</a>
    </div>
    <img src="" alt="" />
    <div class="bt bt--discreto">
      <a @click="() => router.push({ name: 'Home' })">Concluir mais tarde</a>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'
export default {
  setup () {
    const router = useRouter()
    const toast = useToast()
    const user = JSON.parse(window.localStorage.getItem('user'))

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      promocao: {},
      semanas: 0,
      preference: {},
      mp: false
    })

    async function setParcelas () {
      try {
        if (state.semanas >= 1) {
          state.isLoading = true
          const { data, errors } = await services.promocao.prepara({
            qtd: state.semanas,
            token: user.token
          })

          if (!errors) {
            state.preference = data
            console.log('Retorno?', data.id)
            // state.mp = true
            state.isLoading = false
            window.startMp(data.id)
            return
          }

          if (errors.status === 404 || errors.status === 401) {
            toast.error('1 - Problema na comunicação com o Mercado Pago')
          }

          if (errors.status === 422) {
            toast.error(errors.statusText)
          }
          state.isLoading = false
        } else {
          toast.error('Por favor escolha a quantidade de semanas')
        }
      } catch (error) {
        toast.error('2 - Problema na comunicação com o Mercado Pago')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }

    async function setPix () {
      try {
        if (state.semanas >= 1) {
          state.isLoading = true
          const { data, errors } = await services.promocao.preparaPix({
            qtd: state.semanas,
            token: user.token
          })

          if (!errors) {
            state.preference = data
            console.log('Retorno?', data.id)
            // state.mp = true
            state.isLoading = false
            window.startMp(data.id)
            return
          }

          if (errors.status === 404 || errors.status === 401) {
            toast.error('1 - Problema na comunicação com o Mercado Pago')
          }

          if (errors.status === 422) {
            toast.error(errors.statusText)
          }
          state.isLoading = false
        } else {
          toast.error('Por favor escolha a quantidade de semanas')
        }
      } catch (error) {
        toast.error('2 - Problema na comunicação com o Mercado Pago')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }

    async function getPromocao () {
      try {
        state.isLoading = true
        const { data, errors } = await services.promocao.index()

        if (!errors) {
          state.promocao = data
          state.isLoading = false
          return
        }

        if (errors.status === 404 || errors.status === 401) {
          toast.error('Promoção não encontrada')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar a Promoção!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getPromocao()

    return {
      router,
      state,
      setParcelas,
      setPix
    }
  },
  components: { Voltar }
}
</script>
